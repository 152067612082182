import useViewport from "@/lib/useViewport";
import { Section } from "@/ui";

import MobileSlider from "./MobileSlider";
import DesktopGrid from "./DesktopGrid";

const Featured = ({ itineraries = [] }) => {
  const { isTabletUp, isViewportDefined } = useViewport();
  const showSlider = !isTabletUp && isViewportDefined;

  return (
    <Section
      title="Featured Itineraries"
      viewMoreText="Explore more"
      viewMoreLink="/itineraries"
    >
      {showSlider && <MobileSlider itineraries={itineraries} />}
      {!showSlider && <DesktopGrid itineraries={itineraries} />}
    </Section>
  );
};

export default Featured;

import { useEffect, useState } from "react";

import algolia from "@/lib/algolia";
import { Card, Grid, Section, Typography } from "@/ui";
import ItineraryTileLoader from "@/components/ui/loaders/ItineraryTileLoader";

import Topic from "./Topic";

import useStyles from "./styles";

const TopicsPromoTiles = ({ topicsIds = [] }) => {
  const classes = useStyles();
  const [topics, setTopics] = useState([]);
  const [requestComplete, setRequestComplete] = useState(false);
  const [requestError, setRequestError] = useState();
  const { searchAllByFacetValue } = algolia("topics");

  // fetch and set current topics depends of the query
  const fetchTopics = async () => {
    const topicsIdsQuery =
      topicsIds.length > 0
        ? topicsIds.map((topicId) => `topicId:${topicId}`)
        : [];
    try {
      const { hits } = await searchAllByFacetValue([topicsIdsQuery]);
      setTopics(hits);
    } catch (err) {
      setRequestError(err.message);
    } finally {
      setRequestComplete(true);
    }
  };

  const renderLoader = () => (
    <Section
      classes={{ root: classes.loader }}
      title="Related Trip Reports"
      spacing={3}
    >
      <Grid item xs={12} sm={4}>
        <ItineraryTileLoader />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ItineraryTileLoader />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ItineraryTileLoader />
      </Grid>
    </Section>
  );

  const renderError = () => (
    <Grid item xs={12}>
      <Card className={`${classes.card} ${classes.errorCard}`}>
        <Typography variant="h2" align="center" color="error" gutterBottom>
          Sorry, there was a problem loading this data
        </Typography>
        <Typography variant="h4" align="center" color="error">
          {requestError}
        </Typography>
      </Card>
    </Grid>
  );

  // get all topics when component load
  useEffect(() => {
    fetchTopics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section title="Popular Topics" spacing={2} className={classes.root}>
      {!requestComplete && renderLoader()}

      {requestError && renderError()}

      {!requestError && requestComplete && (
        <>
          {(topics || []).slice(-3).map((topic, i) => (
            <Topic key={topic.topicId || i} topic={topic} />
          ))}
        </>
      )}
    </Section>
  );
};

export default TopicsPromoTiles;

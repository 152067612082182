import { trimText } from "@/lib/utils";
import { Typography } from "@/ui";
import { IconFaceHappy, IconFaceSad } from "@/icons";

import useStyles from "./styles";

const Reactions = ({ highlight, lowlight }) => {
  const classes = useStyles();

  return (
    <div className={classes.reactions}>
      {highlight && (
        <Typography component="p" variant="subtitle2">
          <IconFaceHappy />
          {`\u201C${trimText(highlight, 150)}\u201D`}
        </Typography>
      )}

      {lowlight && (
        <Typography component="p" variant="subtitle2">
          <IconFaceSad />
          {`\u201C${trimText(lowlight, 150)}\u201D`}
        </Typography>
      )}
    </div>
  );
};

export default Reactions;

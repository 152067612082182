import { makeStyles } from "@material-ui/core";

const styles = makeStyles(() => ({
  mainContainer: {
    backgroundColor: "#fbfaf6",
    overflowX: "hidden",
  },
}));

export default styles;

import { LazyInView } from "@/components/ui";

import TopicsPromoTiles from "./TopicsPromoTiles";

const LazyTopicsPromoTiles = (props) => (
  <LazyInView rootMargin="800px" unobserveOnEnter>
    <TopicsPromoTiles {...props} />
  </LazyInView>
);

export default LazyTopicsPromoTiles;

import useViewport from "@/lib/useViewport";
import { Grid, Slider } from "@/ui";

import Itinerary from "./Itinerary";

import useStyles from "./styles";

const MobileSlider = ({ itineraries }) => {
  const classes = useStyles();
  const { isMobile } = useViewport();
  const responsiveSlides = isMobile ? 1 : 2;

  return (
    <Grid item xs={12} className={classes.mobileSlider}>
      <Slider
        infinite
        hideArrows
        settings={{
          variableWidth: true,
          slidesToShow: responsiveSlides,
          // slidesToScroll: responsiveSlides,
        }}
        classes={{
          root: classes.slider,
          normal: classes.sliderNormal,
          arrow: classes.sliderArrow,
        }}
      >
        {itineraries.map((itinerary, i) => (
          <Itinerary key={itinerary.itineraryId || i} itinerary={itinerary} />
        ))}
      </Slider>
    </Grid>
  );
};

export default MobileSlider;

import { Button, CardUser } from "@/ui";

import useStyles from "./styles";

const QAndA = ({ qAndA = {} }) => {
  const {
    slug = "",
    itineraryId = "",
    countries = "",
    answer = "",
    userProfile = {},
  } = qAndA || {};
  const classes = useStyles();
  const location = countries[0];

  return (
    <CardUser
      title={location}
      description={`A: ${answer}`}
      userProfile={userProfile}
      classes={{
        root: classes.card,
        container: classes.container,
        summary: classes.summary,
      }}
    >
      <footer className={classes.footer}>
        <Button
          color="secondary"
          href={`/itinerary/${slug}/${itineraryId}`}
          title="See trip report"
          className={classes.button}
        >
          See trip report
        </Button>
      </footer>
    </CardUser>
  );
};

export default QAndA;

import { makeStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: "3em",
  },

  card: {
    height: "100%",
    margin: "0 !important",
    padding: "0 !important",
  },

  errorCard: {
    width: "100%",
  },

  headline: {
    ...theme.typography.h1,
    color: theme.palette.common.white,
  },
});

export default makeStyles(styles);

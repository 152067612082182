import { makeStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  loading: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },

  heroContainer: {
    margin: `${theme.spacing(3)}px 0`,
    display: "flex",
    justifyContent: "center",
  },

  bgCoverImage: {
    display: "none",
    userSelect: "none",
    position: "fixed",
    width: "100%",
    left: 0,
    top: 0,
    zIndex: 0,

    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },

  homepageContent: {
    position: "relative",
    zIndex: 20,
  },

  navTopics: {
    marginBottom: "2em",
  },

  ads: {
    margin: "0 auto 3em",

    [theme.breakpoints.up("md")]: {
      margin: "1em auto 4em",
    },
  },

  emailSignUp: {},

  qaCardBox: {
    marginBottom: theme.spacing(5),
  },

  qaCardContentFooter: {
    maxWidth: "100% !important",
  },

  partnerLegend: {
    paddingLeft: theme.spacing(2),

    [theme.breakpoints.only("sm")]: {
      fontSize: theme.atlasGuru.fontSizes.caption.xs,
    },
  },
});

export default makeStyles(styles);

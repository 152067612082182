import { useEffect, useState } from "react";
import {
  getRegionCover,
  getLocationsPhotos,
  getHeroPhotoMetadata,
  removeEmpty,
} from "@/lib/utils";

import { Hero, Link, Typography } from "@/ui";

import useStyles from "./styles";

const Header = ({ hero = {}, classes: rootClasses }) => {
  const { customPhoto = {}, itinerary = {} } = hero || {};
  const { userProfile = {} } = itinerary || {};
  const {
    cloudId: itineraryCloudId,
    // photoUrl: itineraryPhotoUrl = getRegionCover(itinerary?.regionName),
  } = getHeroPhotoMetadata(
    getLocationsPhotos(itinerary?.locationsVisited || []),
    itinerary?.tripPhotos || []
  );
  const heroLink = itinerary?.url;
  const normalizedDesktop = removeEmpty(customPhoto?.desktop || {});
  const desktopWithDefaults = {
    cloudId: itineraryCloudId,
    photoUrl: getRegionCover(itinerary?.regionName),
    ...normalizedDesktop,
  };
  const firstLoadPhotos = { ...customPhoto, desktop: desktopWithDefaults };

  const classes = useStyles({ classes: rootClasses });
  const [photos, setPhotos] = useState(firstLoadPhotos);

  //  set fallback default photo when there is no custom uploads from admin
  useEffect(() => {
    const newPhotos = {
      ...customPhoto,
      desktop: { ...customPhoto?.desktop },
    };

    // set default cloudId in case no custom is defined
    if (!newPhotos?.desktop?.cloudId) {
      newPhotos.desktop.cloudId = itineraryCloudId;
    }

    setPhotos(newPhotos);
  }, [customPhoto, itineraryCloudId]);

  return (
    <>
      <Hero
        link={heroLink}
        alt={itinerary?.tripTitle}
        userProfile={userProfile}
        photos={photos}
        classes={{
          media: classes.heroMedia,
          userLink: classes.heroUserLink,
        }}
      >
        <Link href={heroLink} className={classes.heroLink}>
          <Typography variant="h1" component="h2" className={classes.headline}>
            {itinerary?.tripTitle}
          </Typography>

          <Typography
            variant="h3"
            component="div"
            className={classes.description}
          >
            {itinerary?.shortDescription}
          </Typography>
        </Link>
      </Hero>
    </>
  );
};

export default Header;

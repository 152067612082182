import {
  getRegionCover,
  getLocationsPhotos,
  getHeroPhotoMetadata,
} from "@/lib/utils";
import { CardHero } from "@/ui";

import Reactions from "./Reactions";
// import Partner from "./Partner";

import useStyles from "./styles";

const Itinerary = ({ itinerary = {} }) => {
  const {
    slug = "",
    tripTitle = "",
    itineraryId = "",
    regionName = "",
    highlight = "",
    lowlight = "",
    numOfDays = 0,
    countries = [],
    tripPhotos = [],
    locationsVisited = [],
    userProfile = {},
  } = itinerary || {};
  const classes = useStyles();
  const { cloudId } = getHeroPhotoMetadata(
    getLocationsPhotos(locationsVisited),
    tripPhotos
  );
  const photoUrl = getRegionCover(regionName);
  const placesList = (countries || []).map((c) => c.name).join(", ");
  const visitedLegend = placesList || "Unknow locations";
  const daysLegend = `${numOfDays || 1} day${numOfDays > 1 ? "s" : ""}`;
  // const isPartner = userProfile?.partner?.id;

  return (
    <div
      className={classes.cardWrapper}
      // className={`${classes.cardWrapper} ${
      //   isPartner ? classes.cardWrapperPartner : ""
      // }`}
    >
      <CardHero
        title={tripTitle}
        description={`${daysLegend} in ${visitedLegend}`}
        href={`/itinerary/${slug}/${itineraryId}`}
        userProfile={userProfile}
        className={classes.card}
        covers={{
          md: {
            cloudId,
            src: photoUrl,
            width: 387,
            height: 218,
          },
          sm: {
            cloudId,
            src: photoUrl,
            width: 448,
            height: 252,
          },
          xs: {
            cloudId,
            src: photoUrl,
            width: 374,
            height: 210,
          },
        }}
      >
        <Reactions highlight={highlight} lowlight={lowlight} />
      </CardHero>

      {/* {isPartner && <Partner partner={userProfile.partner} />} */}
    </div>
  );
};

export default Itinerary;

import { Grid, Section } from "@/ui";

import Itinerary from "./Itinerary";

import useStyles from "./styles";

const ReportsGurus = ({ itineraries = [] }) => {
  const classes = useStyles();

  return (
    <Section
      title="Trip Reports from Gurus"
      viewMoreLink="/itineraries"
      spacing={2}
    >
      {(itineraries || []).map((itinerary, i) => (
        <Grid
          item
          xs={12}
          sm={4}
          key={itinerary.itineraryId || i}
          className={classes.grid}
        >
          <Itinerary itinerary={itinerary} />
        </Grid>
      ))}
    </Section>
  );
};

export default ReportsGurus;

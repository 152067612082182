const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    margin: `0 -${theme.gutter}px 5em`,
    padding: "4em 2em",
    textAlign: "center",
  },

  title: {
    color: theme.palette.common.white,
  },

  info: {
    ...theme.typography.caption,
    color: theme.palette.common.white,
    margin: "1.5em 0",
  },
});

export default styles;

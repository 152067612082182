import { Grid } from "@/ui";

import Itinerary from "./Itinerary";

import useStyles from "./styles";

const DesktopGrid = ({ itineraries }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.desktopGrid}>
      <Grid container spacing={2}>
        {itineraries.map((itinerary, i) => (
          <Grid
            item
            xs={12}
            sm={4}
            key={itinerary.itineraryId || i}
            className={classes.grid}
          >
            <Itinerary itinerary={itinerary} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default DesktopGrid;

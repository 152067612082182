import { makeStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    textAlign: "center",
    minHeight: "2.2em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.up("sm")]: {
      margin: `0 auto ${theme.spacing(2)}px`,
      maxWidth: "80%",
    },

    "& h1": {
      color: theme.palette.primary.main,
    },

    "& a, & svg": {
      "&, &:hover": {
        color: `${theme.palette.common.highlight} !important`,
      },
    },

    "& a": {
      alignItems: "center",
      display: "inline-flex",
      margin: "0 0.5em",
    },

    "& svg": {
      color: theme.palette.common.white,
      height: "0.8em",
      marginBottom: "0.5em",
      width: "0.8em",
    },
  },
});

export default makeStyles(styles);

import { CardHero, Grid } from "@/ui";

import { trimText, getRegionCover } from "@/lib/utils";

import useStyles from "./styles";

const Topic = ({ topic = {} }) => {
  const { url, cloudId, region, tripPhoto, topicTitle, topicDescription } =
    topic || {};
  const classes = useStyles();
  const src = tripPhoto || getRegionCover(region);

  return (
    <Grid item xs={12} sm={4}>
      <CardHero
        href={url}
        headline={topicTitle}
        description={trimText(topicDescription, 150)}
        classes={{
          root: classes.card,
          headline: classes.headline,
        }}
        covers={{
          md: {
            cloudId,
            src,
            width: 387,
            height: 217,
          },
          sm: {
            cloudId,
            src,
            width: 287,
            height: 161,
          },
          xs: {
            cloudId,
            src,
            width: 374,
            height: 210,
          },
        }}
      />
    </Grid>
  );
};

export default Topic;

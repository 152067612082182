import { Grid, Section } from "@/ui";

import QAndA from "./QAndA";

import useStyles from "./styles";

const QAndAPanel = ({ qAndAPanel = {} }) => {
  const { question = "", qAndAs = [] } = qAndAPanel || {};
  const classes = useStyles();

  return (
    <Section
      title="Q & A"
      subtitle={`Q: ${question}`}
      viewMoreLink="/itineraries"
      classes={{ subtitle: classes.subtitle }}
    >
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {(qAndAs || []).map((qAndA, i) => (
            <Grid
              item
              xs={12}
              sm={4}
              key={qAndA.itineraryId || i}
              className={classes.grid}
            >
              <QAndA qAndA={qAndA} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Section>
  );
};

export default QAndAPanel;

import theme from "@/lib/theme";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  grid: {
    display: "flex",
  },

  card: {
    height: "100%",
    width: "100%",

    "& figcaption": {
      paddingBottom: theme.spacing(1),
    },
  },
}));

export default useStyles;

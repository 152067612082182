import PropTypes from "prop-types";
import Card from "../Card";
import { Placeholder } from "../decorations";

function ItineraryTileLoader({ classes }) {
  const cardInfo = {
    cardInfo: {
      description: <Placeholder rows={4} rowSize={15} />,
      title: <Placeholder rowSize={25} gutterBottom={20} />,
    },
    userInfo: {
      screenName: "",
      profilePicUrl: "",
    },
  };

  return (
    <Card
      cardLink={false}
      customClasses={classes}
      disableUserLink
      isLoader
      variant="cardPhoto"
      {...cardInfo}
    />
  );
}

ItineraryTileLoader.defaultProps = {
  classes: {},
};

ItineraryTileLoader.propTypes = {
  classes: PropTypes.object,
};

export default ItineraryTileLoader;

import {
  getRegionCover,
  getLocationsPhotos,
  getHeroPhotoMetadata,
} from "@/lib/utils";
import { CardHero } from "@/ui";

import useStyles from "./styles";

const Itinerary = ({ itinerary = {} }) => {
  const {
    slug = "",
    tripTitle = "",
    itineraryId = "",
    regionName = "",
    numOfDays = 0,
    countries = [],
    tripPhotos = [],
    locationsVisited = [],
    userProfile = {},
  } = itinerary || {};
  const { cloudId } = getHeroPhotoMetadata(
    getLocationsPhotos(locationsVisited),
    tripPhotos
  );
  const photoUrl = getRegionCover(regionName);
  const placesList = (countries || []).map((c) => c.name).join(", ");
  const visitedLegend = placesList || "Unknow locations";
  const daysLegend = `${numOfDays || 1} day${numOfDays > 1 ? "s" : ""}`;
  const classes = useStyles();

  return (
    <CardHero
      title={tripTitle}
      description={`${daysLegend} in ${visitedLegend}`}
      href={`/itinerary/${slug}/${itineraryId}`}
      userProfile={userProfile}
      className={classes.card}
      covers={{
        md: {
          cloudId,
          src: photoUrl,
          width: 387,
          height: 218,
        },
        sm: {
          cloudId,
          src: photoUrl,
          width: 287,
          height: 161,
        },
        xs: {
          cloudId,
          src: photoUrl,
          width: 366,
          height: 205,
        },
      }}
    />
  );
};

export default Itinerary;

import { Link, Typography } from "@/ui";
import { IconArrow } from "@/icons";

import useStyles from "./styles";

const BannerHowItWorks = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="body1">
        {`AtlasGuru combines AI and travelers' stories to create personalized itineraries and matches travelers with expert travel advisors.`}
        <Link
          href="/how-it-works"
          title="Read more about how to publish in AtlasGuru"
        >
          Learn more
          <IconArrow variant="right" />
        </Link>
      </Typography>
    </div>
  );
};

export default BannerHowItWorks;

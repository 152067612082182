import theme from "@/lib/theme";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {},

  subtitle: {
    ...theme.typography.h3,
  },

  grid: {
    display: "flex",
  },

  card: {
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexFlow: "column",
      textAlign: "center",
    },
  },

  container: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexFlow: "column",
      flex: 1,
    },
  },

  summary: {
    flex: 1,
  },

  footer: {
    marginTop: "1.2em",
  },

  button: {
    background: "none !important",
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "uppercase",
    textDecoration: "underline !important",
  },
}));

export default useStyles;
